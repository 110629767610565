import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.headerMenuItems = app.headerMenuItems || createConfigurableObject();
    app.headerMenuItems.addMany({
        dashboard: {
            label: 'Dashboard',
            link: '/dashboard',
        },
        logout: {
            hasBorder: true,
            label: 'Logout',
            link: '/logout',
        }
    })
}