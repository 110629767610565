import get from 'lodash/get';
import values from 'lodash/values';
import { selectors } from '@skunexus/utils';
import { withTabs } from '@skunexus/component-catalog';

import UserModel from '../../../UserModel';
import { filterAccessibleItems } from '../../../utils';

import {
    VENDORS_GRID,
    VENDORS_FORM,
    VENDORS_CREATE,
    VENDORS_EDIT,
    VENDORS_DETAILS,
    VENDOR_ADDRESSES_GRID,
    VENDOR_ADDRESSES_FORM,
    VENDOR_ADDRESSES_ADD,
    VENDOR_ADDRESSES_EDIT,
    VENDOR_CONTACTS_GRID,
    VENDOR_CONTACTS_FORM,
    VENDOR_CONTACTS_ADD,
    VENDOR_CONTACTS_EDIT,
    PRODUCT_OVERVIEW,
    PURCHASE_ORDER_ADD,
    PURCHASE_ORDER_OVERVIEW,
    PURCHASE_ORDERS_GRID,
    ORDERS_GRID,
} from './consts';

export default (app) => ({
    vendors: {
        path: VENDORS_GRID,
        title: 'Vendors',
        permission: ['vendors.index'],
        props: () => ({
            detailsPageRoute: get(app.vendorTabs.getAll(), 'overview.link', ''),
            formPageRouteAdd: VENDORS_CREATE,
            formPageRouteEdit: `${VENDORS_EDIT}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-grid'),
    },
    vendorForm: {
        path: VENDORS_FORM,
        title: 'Vendor Form',
        permission: ['vendors.create', 'vendors.update'],
        props: () => ({
            indexPageRoute: VENDORS_GRID,
            detailsPageRoute: `${VENDORS_DETAILS}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-form'),
    },
    vendorOverview: {
        path: app.vendorTabs.getItem('overview').link.replace(':vendorId', ':id'),
        title: app.vendorTabs.getItem('overview').title,
        permission: app.vendorTabs.getItem('overview').permission,
        props: () => ({
            formPageRoute: `${VENDORS_EDIT}/:vendorId`,
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            permissions: {
                editOnlyAdmin: UserModel.getCurrentUser().hasAllPermissions('vendors.update'),
                viewAttributes: true,
                editAttributes:
                    !UserModel.isVendor() &&
                    UserModel.getCurrentUser().hasAllPermissions('vendors - custom attributes.value edit'),
            },
            visibleAttributes: UserModel.isVendor()
                ? app.general.getItem('modules.vendorOverview.vendorVisibleAttributes')
                    ? app.general.getItem('modules.vendorOverview.vendorVisibleAttributes')
                    : []
                : undefined,
        }),
        loader: () => import('@skunexus/vendor-overview'),
    },
    vendorAddresses: {
        path: app.vendorTabs.getItem('addresses').link,
        title: app.vendorTabs.getItem('addresses').title,
        permission: app.vendorTabs.getItem('addresses').permission,
        props: (state) => ({
            formAddPageRoute: VENDOR_ADDRESSES_ADD,
            formEditPageRoute: VENDOR_ADDRESSES_EDIT,
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            idNameMap: selectors.getIDNameMap(state.app),
        }),
        loader: () => import('@skunexus/vendor-address-grid'),
    },
    vendorAddressesForm: {
        path: VENDOR_ADDRESSES_FORM,
        title: 'Vendor',
        permission: ['vendors - addresses.create', 'vendors - addresses.update'],
        props: () => ({
            indexPageRoute: `${VENDOR_ADDRESSES_GRID}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-address-form'),
    },
    vendorContacts: {
        path: app.vendorTabs.getItem('contacts').link,
        title: app.vendorTabs.getItem('contacts').title,
        permission: app.vendorTabs.getItem('contacts').permission,
        props: (state) => ({
            formAddPageRoute: VENDOR_CONTACTS_ADD,
            formEditPageRoute: VENDOR_CONTACTS_EDIT,
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            idNameMap: selectors.getIDNameMap(state.app),
        }),
        loader: () => import('@skunexus/vendor-contact-grid'),
    },
    vendorContactForm: {
        path: VENDOR_CONTACTS_FORM,
        title: 'Vendor Contact Form',
        permission: ['vendors - contacts.create', 'vendors - contacts.update'],
        props: () => ({
            indexPageRoute: `${VENDOR_CONTACTS_GRID}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-contact-form'),
    },

    vendorProducts: {
        path: app.vendorTabs.getItem('products').link,
        title: app.vendorTabs.getItem('products').title,
        permission: app.vendorTabs.getItem('products').permission,
        props: (state) => ({
            productDetailsPageRoute: PRODUCT_OVERVIEW,
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            permissions: {
                create: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.create'),
                deactive: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.update'),
                delete: UserModel.getCurrentUser().hasAnyPermissions([
                    'vendors - products.delete',
                    'vendors - products.delete multiple',
                ]),
            },
            canEditPrice: true,
            canEditSku: true,
            idNameMap: selectors.getIDNameMap(state.app),
        }),
        loader: () => import('@skunexus/vendor-product-grid'),
    },
    vendorPurchaseOrders: {
        path: app.vendorTabs.getItem('purchaseOrders').link,
        title: app.vendorTabs.getItem('purchaseOrders').title,
        permission: app.vendorTabs.getItem('purchaseOrders').permission,
        props: (state) => ({
            formAddPageRoute: PURCHASE_ORDER_ADD,
            viewPageRoute: PURCHASE_ORDER_OVERVIEW,
            purchaseOrderDetailsPageRoute: PURCHASE_ORDERS_GRID,
            purchaseOrderDetailsPageRouteSuffix: 'overview',
            orderDetailsPageRoute: ORDERS_GRID,
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            permissions: {
                create: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
                orderOnlyAdmin: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
            },
            idNameMap: selectors.getIDNameMap(state.app),
        }),
        loader: () => import('@skunexus/vendor-purchase-order-grid'),
    },
    vendorReports: {
        path: app.vendorTabs.getItem('reportsAndPayouts').link,
        title: app.vendorTabs.getItem('reportsAndPayouts').title,
        permission: app.vendorTabs.getItem('reportsAndPayouts').permission,
        props: (state) => ({
            tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
            idNameMap: selectors.getIDNameMap(state.app),
        }),
        loader: () => {
            return import('@skunexus/vendor-reports-payouts').then((ModuleConfig) => {
                return {
                    ...ModuleConfig,
                    Module: ModuleConfig.MerchantModule,
                };
            });
        },
    },
    vendorDashboardSettings: {
        path: app.settingsVendorTabs.getItem('dashboard').link,
        title: app.settingsVendorTabs.getItem('dashboard').title,
        permission: app.settingsVendorTabs.getItem('dashboard').permission,
        loader: () => import('@skunexus/vendors-daashboard-settings'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(values(app.settingsVendorTabs.getAll()), { sub: true })(Component),
            ),
    },
});
