import React from 'react';
import styles from "../styles.module.css";
import { Row, Col } from 'reactstrap';
import {getAddressDetails, getRowValue} from "../utils/helpers";

export default (
  {
    label,
    billingAddress: {first_name, street1, city, postcode, country},
    shippingAddress: {street1: street1_ship, city: city_ship, postcode: postcode_ship, country: country_ship}
  }
) => {

  return (
    <section className={styles.orderDetails}>
      <h3>Details</h3>

      <Row>
        <Col>
          { getRowValue('Magento Order ID', label) }
        </Col>
      </Row>
      <Row>
        <Col>
          { getRowValue('Customer Name', first_name) }
        </Col>
      </Row>

      <Row xs="2" className={styles.addressSection}>
        <Col>
          { getRowValue('Customer Billing Address') }

          <ul className={styles.addressInfo}>
            { getAddressDetails(street1, city, postcode, country) }
          </ul>
        </Col>

        <Col>
          { getRowValue('Customer Shipping Address') }

          <ul className={styles.addressInfo}>
            { getAddressDetails(street1_ship, city_ship, postcode_ship, country_ship) }
          </ul>
        </Col>
      </Row>
    </section>
  )
}