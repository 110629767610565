export default app => ({
        receiveInventory: {
            id: 1,
            label: 'Receive Inventory',
            link: '/receive-inventory/main',
            order: 10
        },
        itemsReceived: {
            id: 2,
            label: 'Items Received',
            link: '/receive-inventory/items',
            order: 20
        },
        scanned: {
            id: 3,
            label: 'Scanned Items to Put Away',
            link: '/receive-inventory/put-away',
            order: 30
        },
        finalized: {
            id: 4,
            label: 'Finalized',
            link: '/receive-inventory/put-away-final',
            order: 40
        }
})