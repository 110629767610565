import routes from './routes';

export default app => {
    
    app.settingsTabs.addItem('integrations', {
        label: 'Integrations',
        title: 'Integrations',
        link: '/settings/integrations',
        permission: 'connectors.index',
        order: 20
    });

    app.routes.addMany(routes(app));
}