export default app => ({
    failed: {
        id: 'Failed Jobs',
        label: 'Failed Jobs',
        title: 'Queue > Failed Jobs',
        link: '/queue/failed',
        permission: [],
        order: 10
    },
    spawned: {
        id: 'Spawned Jobs',
        label: 'Spawned Jobs',
        title: 'Queue > Spawned Jobs',
        link: '/queue/spawned',
        permission: [],
        order: 20
    }
})