import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import values from 'lodash/values';
import get from 'lodash/get';

import { CurrentUserModel } from '@skunexus/auth';
import { MainLayout } from '@skunexus/component-catalog';
import { actions as appActions } from '@skunexus/utils';

import UserModel from './UserModel';

import app from './config';

export const keyedRoutes = (routes) =>
    Object.keys(routes).map((key) => ({
        name: get(routes[key], 'name', key),
        ...routes[key],
    }));

export const filterAccessibleItems = (items) =>
    value(items).filter((item) => {
        let permissions = value(get(item, 'permission', []));
        if (!Array.isArray(permissions)) {
            permissions = [permissions];
        }
        return !permissions.length || CurrentUserModel.hasAnyPermissions(permissions);
    });

export const combinePermissions = (items) =>
    value(items).reduce((result, item) => {
        let permissions = value(get(item, 'permission', []));
        return result.concat(Array.isArray(permissions) ? permissions : [permissions]);
    }, []);

export const firstAllowedLink = (items) => {
    const item = filterAccessibleItems(items).shift();
    return item !== undefined ? value(item.link) : '';
};

export const value = (item) => (item instanceof Function ? item() : item);

export const createMainLayout = (layoutProps) => {
    const mapStateToProps = (state) => ({
        logoImage: app.general.getItem('logoImage'),
        pageName: app.selectors.getItem('getPageName')(state),
        isSidebarOpened: app.selectors.getItem('getIsSidebarMenuOpen')(state),
        ...value(layoutProps),
        userName: `${get(CurrentUserModel.getExtra(), 'first_name', UserModel.isVendor() ? 'Vendor' : 'User')} ${get(
            CurrentUserModel.getExtra(),
            'last_name',
            '',
        )}`.trim(),
        roleName: CurrentUserModel.getRoles().join(', '),
    });

    const mapDispatchToProps = (disaptch) =>
        bindActionCreators(
            {
                toggleSidebar: appActions.toggleSidebarMenu,
            },
            disaptch,
        );

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(({ sideMenuItems, ...props }) => {
        const accessibleItems = filterAccessibleItems(sideMenuItems);
        return <MainLayout {...props} sideMenuItems={accessibleItems} />;
    });
};

export const sortModules = (setupModules) => {
    const dependencies = {};
    values(setupModules).forEach(function (item) {
        if (!dependencies.hasOwnProperty(item.name)) {
            dependencies[item.name] = [];
        }
        if (item.before) {
            item.before.forEach(function (dep) {
                if (!dependencies.hasOwnProperty(dep)) {
                    dependencies[dep] = [];
                }
                dependencies[dep].push(item.name);
            });
        }
        if (item.after) {
            item.after.forEach(function (dep) {
                dependencies[item.name].push(dep);
            });
        }
    });

    let keys = Object.keys(dependencies),
        used = new Set(),
        result = [],
        i,
        item,
        length;
    do {
        length = keys.length;
        i = 0;
        while (i < keys.length) {
            if (dependencies[keys[i]].every(Set.prototype.has, used)) {
                item = keys.splice(i, 1)[0];
                result.push(item);
                used.add(item);
                continue;
            }
            i++;
        }
    } while (keys.length && keys.length !== length);

    result.push(...keys);

    return result.map((moduleName) => setupModules[moduleName]);
};
