import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import app from '../config';
import { createRoutes } from '../createRoutes';
import logout from '../routes/logout';

import { filterAccessibleItems, keyedRoutes } from '../utils';
import MainLayout from './MainLayout.js';

export default () => {
    const accessibleRoutes = filterAccessibleItems(keyedRoutes(app.vendorRoutes.getAll()));

    return (
        <Switch>
            {createRoutes(accessibleRoutes, MainLayout())}
            {logout()}
            <Redirect path='*' to={`/dashboard`} />
        </Switch>
    );
};
