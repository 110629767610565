import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';


export default app => ({
    users: {
        path: '/users',
        title: 'Users',
        permission: ['other.all'],
        loader: () => import('@skunexus/users'),
        props: () => ({
            detailsPageRoute: '/users',
        }),
    },
    userDetails: {
        path: '/users/:id',
        title: 'User Details',
        permission: ['other.all'],
        loader: () => import('@skunexus/user-details'),
    },
    userSettings: {
        path: app.settingsTabs.getItem('users').link,
        title: app.settingsTabs.getItem('users').title,
        permission: app.settingsTabs.getItem('users').permission,
        props: () => (
            {
                detailsPageRoute: '/settings/users'
            }
        ),
        loader: () => import('@skunexus/users'),
        // loader: () => import('../../../packages/smst-fe-users-module/src/core'),
        render: Component => withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(Component)
    },
    usersSettingsDetail: {
        path: '/settings/users/:id',
        title: 'User Details',
        permission: 'users.show',
        loader: () => import('@skunexus/user-details')
    },
    rolesSettings: {
        path: app.settingsTabs.getItem('roles').link,
        title: app.settingsTabs.getItem('roles').title,
        permission: app.settingsTabs.getItem('roles').permission,
        loader: () => import('@skunexus/roles'),
        render: Component => withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(Component)
    }
});