export default (app) => {
    app.routes.addMany({
        rmaGrid: {
            path: '/rma',
            title: 'RMA',
            permission: ['rma.index'],
            props: () => ({
                detailsPageRoute: '/rma/details',
                orderDetailsPageRoute: '/orders',
            }),
            loader: () => import('@skunexus/rma-grid'),
        },
        rmaDetails: {
            path: '/rma/details/:id',
            title: 'RMA - Details',
            permission: ['rma.show'],
            props: () => ({
                orderDetailsPageRoute: '/orders',
            }),
            loader: () => import('@skunexus/rma-details'),
        },
    });
    app.sideMenuItems.addItem('rma', {
        label: 'RMA',
        link: '/rma',
        icon: 'resInventory',
        permission: 'rma.index',
        order: 50,
    });
};
