import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils';

import queueTabs from './queueTabs';
import routes from './routes';

export default app => {
    
    app.queueTabs = app.queueTabs || createConfigurableObject();
    app.queueTabs.addMany(queueTabs(app));

    app.settingsTabs.addItem('queue', {
        label: 'Queue Jobs',
        link: () => firstAllowedLink(values(app.queueTabs.getAll())),
        permission: () => combinePermissions(values(app.queueTabs.getAll())),
        order: 40
    })

    app.routes.addMany(routes(app));
}