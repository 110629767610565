export default app => ({
    carriers: {
        id: 'Carriers & Services',
        label: 'Carriers & Services',
        link: '/settings/shipping-options/carriers',
        permission: 'shipping - carriers.index',
        order: 10
    },
    classCodes: {
        id: 'Class Codes',
        label: 'Class Codes',
        link: '/settings/shipping-options/class-codes',
        permission: 'shipping - class codes.index',
        order: 20
    },
    optionMappers: {
        id: 'Shipping Option Mappers',
        label: 'Shipping Option Mappers',
        link: '/settings/shipping-options/mapper',
        permission: 'shipping - option mappers.index',
        order: 30
    },
    parcels: {
        id: 'Parcels',
        label: 'Parcels',
        link: '/settings/shipping-options/parcels',
        permission: 'shipping - packages.index',
        order: 40
    },
})