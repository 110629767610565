import React from 'react';
import styles from "../styles.module.css";
import { Table } from 'reactstrap';
import {getTdValue} from "../utils/helpers";

export default ({prescription, title = false}) => {
  const errorMessage = <h2>Prescription not found</h2>

  if (!prescription) {
    return errorMessage;
  }

  const {lenses: {od, os}} = prescription;

  return (
    <>
      {title && <h3>Prescription</h3>}

      <Table bordered className={styles.prescriptionTable}>
        <thead>
          <tr>
            <th></th>
            <th>Sph</th>
            <th>BC</th>
            <th>DIA</th>
            <th>Cyl</th>
            <th>Axis</th>
            <th>Add</th>
            <th>Multifocal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className={styles.rowName}>OD (Right)</th>
            <td>{ getTdValue(od.odspheretext) }</td>
            <td>{ getTdValue(od.clensodbasecrv1) }</td>
            <td>{ getTdValue(od.clensoddiam1) }</td>
            <td>{ getTdValue(od.odcylindertext) }</td>
            <td>{ getTdValue(od.odaxis) }</td>
            <td>{ getTdValue(od.od_add) }</td>
            <td>{ getTdValue(od.od_multifocal) }</td>
          </tr>
          <tr>
            <th scope="row" className={styles.rowName}>OG (Left)</th>
            <td>{ getTdValue(os.osspheretext) }</td>
            <td>{ getTdValue(os.clensosbasecrv1) }</td>
            <td>{ getTdValue(os.clensosdiam1) }</td>
            <td>{ getTdValue(os.oscylindertext) }</td>
            <td>{ getTdValue(os.osaxis) }</td>
            <td>{ getTdValue(os.os_add) }</td>
            <td>{ getTdValue(os.os_multifocal) }</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
