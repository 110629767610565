import createConfigurableObject from '../../../utils/configurationObject';
import routes from './routes';
import tabs from './tabs';
import sideMenuItem from './sideMenu';

export default app => {

    app.settingsGlobalTabs.addItem('adjustment-stock-reasons', {
        id: 'adjustment-stock-reasons',
        label: 'Adjustment Stock Reasons',
        title: 'Adjustment Stock Reasons',
        link: '/settings/global/adjustment-stock-reasons',
        permission: 'settings - general.save',
    });

    app.productDetailsTabs = app.productDetailsTabs || createConfigurableObject();
    app.productDetailsTabs.addMany(tabs(app))
    app.routes.addMany(routes(app));
    app.sideMenuItems.addItem('products', sideMenuItem(app))
}