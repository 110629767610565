export default app => ({
    dashboard: {
        label: 'Dashboard',
        link: '/dashboard',
        icon: 'overview',
    },
    overview: vendorId => ({
        label: 'General Information',
        link: `/vendors/${vendorId}/overview`,
        icon: 'vendors',
    }),
    products: vendorId => ({
        label: 'Products',
        link: `/vendors/${vendorId}/products`,
        icon: 'stock',
        permission: 'vendors - products.index',
    }),
    contacts: vendorId => ({
        label: 'Contacts',
        link: `/vendors/${vendorId}/contacts`,
        icon: 'users',
        permission: 'vendors - contacts.index',
    }),
    purchaseOrder: vendorId => ({
        label: 'Purchase Orders',
        link: `/vendors/${vendorId}/purchase-orders`,
        icon: 'purchase',
        permission: 'purchase orders.index',
    }),
    reports: vendorId => ({
        label: 'Reports & Payouts',
        link: `/vendors/${vendorId}/reports`,
        icon: 'resInventory'
    }),
})