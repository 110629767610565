import React from 'react';
import values from 'lodash/values';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import { filterAccessibleItems } from '../../../../utils';
import app from '../../../../config';

const MainTabsLayout = ({ children, ...props }) => {
    const states = () => {
        const pickupFulfillmentTabs = app.pickupFulfillmentTabs.getAll();

        if (pickupFulfillmentTabs.pick) {
            pickupFulfillmentTabs.pick.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pick"}',
            };
        }

        if (pickupFulfillmentTabs.pack) {
            pickupFulfillmentTabs.pack.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pack"}',
            };
        }

        if (pickupFulfillmentTabs.groupPick) {
            pickupFulfillmentTabs.groupPick.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        if (pickupFulfillmentTabs.waves) {
            pickupFulfillmentTabs.waves.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        return values(app.pickupFulfillmentTabs.getAll());
    };

    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider {...props} mapState={app.selectors.getItem('getFulfillmentMainTabs')}>
                    <MainTabs states={filterAccessibleItems(states)} cartScannerEnable={true}  domain='inStoreFulfillment' {...props} />
                </SubspaceProvider>
            </ParentSpaceProvider>
            {children}
        </div>
    );
};

export default MainTabsLayout;
