
import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils';

import routes from './routes';
import pickupFulfillmentRoutes from './pickupFulfillmentRoutes';
import shipTabs from './shipTabs';
import wavesTabs from './wavesTabs';
import pickupTabs from './pickupTabs';
import settingsFulfillmentTabs from './settingsFulfillmentTabs';
import sideMenuItem from './sideMenu';

export default app => {
    app.fulfillmentWavesTabs = app.fulfillmentWavesTabs || createConfigurableObject();
    app.fulfillmentWavesTabs.addMany(wavesTabs(app));

    app.fulfillmentShipTabs = app.fulfillmentShipTabs || createConfigurableObject();
    app.fulfillmentShipTabs.addMany(shipTabs(app));
    
    app.pickupFulfillmentTabs = app.pickupFulfillmentTabs || createConfigurableObject();
    app.pickupFulfillmentTabs.addMany(pickupTabs(app))

    app.settingsFulfillmentTabs = app.settingsFulfillmentTabs || createConfigurableObject();
    app.settingsFulfillmentTabs.addMany(settingsFulfillmentTabs(app));

    app.settingsTabs.addItem('fulfillment', {
        label: 'Fulfillment',
        link: () => firstAllowedLink(values(app.settingsFulfillmentTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsFulfillmentTabs.getAll())),
        order: 12
    })
    
    app.routes.addMany(routes(app));
    app.routes.addMany(pickupFulfillmentRoutes(app));
    
    app.sideMenuItems.addItem('fulfillment', sideMenuItem(app))
}