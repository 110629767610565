import forEach from 'lodash/forEach';

const routesToCopy = [
    'purchaseOrderOverview',
    'purchaseOrderPrint',
];

export default app => {
   
    forEach(routesToCopy, routeName => {
        const route = Object.assign({}, app.routes.getItem(routeName));
        if (route) {
            app.vendorRoutes.addItem(routeName, route);
        }
    });

    const purchaseOrderOverviewRouter = app.vendorRoutes.getItem('purchaseOrderOverview');
    if (purchaseOrderOverviewRouter) {

        const purchaseOrderOverviewProps = purchaseOrderOverviewRouter.props;
        purchaseOrderOverviewRouter.props = state => (
            {
                ...purchaseOrderOverviewProps(state),
                permissions: {
                    allowedStatuses: app.general.getItem('modules.purchaseOrder.vendorStatuses')
                },
            }
        );
        
        app.vendorRoutes.changeItem('purchaseOrderOverview', purchaseOrderOverviewRouter)

    }
}