import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';


export default app => ({
    settingsProductAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('productAttributes').link,
        title: app.settingsAttributesTabs.getItem('productAttributes').title,
        permission: app.settingsAttributesTabs.getItem('productAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('productAttributes').link}/attribute-form`,
                domain: 'product',
                entityName: 'Product',
                basePath: 'products'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsProductAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('productAttributes').link}/attribute-form/:id?`,
        title: 'Product Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('productAttributes').link,
                domain: 'product',
                entityName: 'Product',
                basePath: 'products'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsOrderAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('orderAttributes').link,
        title: app.settingsAttributesTabs.getItem('orderAttributes').title,
        permission: app.settingsAttributesTabs.getItem('orderAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('orderAttributes').link}/order-attribute-form`,
                domain: 'order',
                entityName: 'Order',
                basePath: 'orders'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsOrderAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('orderAttributes').link}/order-attribute-form/:id?`,
        title: 'Order Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('orderAttributes').link,
                domain: 'order',
                entityName: 'Order',
                basePath: 'orders'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsPOAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('poAttributes').link,
        title: app.settingsAttributesTabs.getItem('poAttributes').title,
        permission: app.settingsAttributesTabs.getItem('poAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('poAttributes').link}/purchase-order-attribute-form`,
                domain: 'purchaseOrder',
                entityName: 'Purchase Order',
                basePath: 'purchase-order'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsPOAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('poAttributes').link}/purchase-order-attribute-form/:id?`,
        title: 'Purchase Order Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('poAttributes').link,
                domain: 'purchaseOrder',
                entityName: 'Purchase Order',
                basePath: 'purchase-order'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsVendorAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('vendorAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('vendorAttributes').link}/attribute-form`,
                domain: 'vendor',
                entityName: 'Vendor',
                basePath: 'vendors'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsVendorAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('vendorAttributes').link}/attribute-form/:id?`,
        title: 'Vendor Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('vendorAttributes').link,
                domain: 'vendor',
                entityName: 'Vendor',
                basePath: 'vendors'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsUserAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('userAttributes').link,
        title: app.settingsAttributesTabs.getItem('userAttributes').title,
        permission: app.settingsAttributesTabs.getItem('userAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('userAttributes').link}/attribute-form`,
                domain: 'user',
                entityName: 'User',
                basePath: 'Users'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsUserAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('userAttributes').link}/attribute-form/:id?`,
        title: 'User Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('userAttributes').link,
                domain: 'user',
                entityName: 'User',
                basePath: 'users'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsWarehouseAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('warehouseAttributes').link,
        title: app.settingsAttributesTabs.getItem('warehouseAttributes').title,
        permission: app.settingsAttributesTabs.getItem('warehouseAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('warehouseAttributes').link}/attribute-form`,
                domain: 'warehouse',
                entityName: 'Warehouse',
                basePath: 'warehouses'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsWarehouseAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('warehouseAttributes').link}/attribute-form/:id?`,
        title: 'Warehouse Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('warehouseAttributes').link,
                domain: 'warehouse',
                entityName: 'Warehouse',
                basePath: 'warehouses'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsCustomerAttributes: {
        name: 'settingsCustomAttributes',
        path: app.settingsAttributesTabs.getItem('customerAttributes').link,
        title: app.settingsAttributesTabs.getItem('customerAttributes').title,
        permission: app.settingsAttributesTabs.getItem('customerAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('customerAttributes').link}/attribute-form`,
                domain: 'customer',
                entityName: 'Customer',
                basePath: 'customers'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsCustomerAttributeForm: {
        name: 'settingsCustomAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('customerAttributes').link}/attribute-form/:id?`,
        title: 'Customer Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('customerAttributes').link,
                domain: 'customer',
                entityName: 'Customer',
                basePath: 'customers'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    },
    settingsVendorProductAttributes: {
        name: 'settingsVendorProductAttributes',
        path: app.settingsAttributesTabs.getItem('vendorProductAttributes').link,
        title: app.settingsAttributesTabs.getItem('vendorProductAttributes').title,
        permission: app.settingsAttributesTabs.getItem('vendorProductAttributes').permission,
        props: () => (
            {
                formPageRoute: `${app.settingsAttributesTabs.getItem('vendorProductAttributes').link}/attribute-form`,
                domain: 'vendorProduct',
                entityName: 'Vendor Product',
                basePath: 'vendor-products'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsAttributesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    settingsVendorProductAttributesForm: {
        name: 'settingsVendorProductAttributesForm',
        path: `${app.settingsAttributesTabs.getItem('vendorProductAttributes').link}/attribute-form/:id?`,
        title: 'Vendor Product Attribute Form',
        props: () => (
            {
                indexPageRoute: app.settingsAttributesTabs.getItem('vendorProductAttributes').link,
                domain: 'vendorProduct',
                entityName: 'Vendor Product',
                basePath: 'vendor-products'
            }
        ),
        loader: () => import('@skunexus/settings-custom-attributes-form'),
    }
});