export default app => ({
    users: {
        label: 'Users',
        title: 'Users',
        link: '/settings/users',
        permission: 'users.index',
        order: 30
    },
    roles: {
        label: 'Roles',
        title: 'Roles',
        link: '/settings/roles',
        permission: 'roles.index',
        order: 40
    }
})