import { CurrentUserModel } from '@skunexus/auth';
import get from 'lodash/get';

class Model {
  isVendor() {
    return !!this.getVendorId();
  }

  getVendorId() {
    return get(CurrentUserModel.getExtra(), 'vendor_id');
  }

  getUserId() {
    return get(CurrentUserModel.getExtra(), 'user_id');
  }

  getCurrentUser() {
    return CurrentUserModel;
  }
}

const UserModel = new Model();

export { CurrentUserModel };

export default UserModel;
