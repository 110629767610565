import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.sideMenuItems = app.sideMenuItems || createConfigurableObject();
    app.sideMenuItems.addItem('dashboard', {
        label: 'Dashboard',
        link: '/dashboard',
        icon: 'overview',
        order: 10
    })
}