import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';


export default app => ({
    integrations: {
        path: '/integrations',
        title: 'Integrations',
        permission: ['integrations.index'],
        loader: () => import('@skunexus/integrations'),
        props: () => ({
            detailsPageRoute: '/integrations',
        }),
    },
    settingIntegrations: {
        path: app.settingsTabs.getItem('integrations').link,
        title: app.settingsTabs.getItem('integrations').title,
        permission: app.settingsTabs.getItem('integrations').permission,
        loader: () => import('@skunexus/integrations'),
        render: Component => withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(Component)
    }
})