import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.vendorRoutes = app.vendorRoutes || createConfigurableObject();

    app.vendorRoutes.addItem('dashboard', {
        exact: true,
        path: '/:path(|dashboard)',
        title: 'Dashboard',
        loader: () => import('@skunexus/vendor-dashboard'),
    });

}