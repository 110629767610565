import React from 'react';
import styles from "../styles.module.css";
import { Row, Col } from 'reactstrap';
import PrescriptionTable from '../PrescriptionTable'

const TableContainer = ({isRender, prescription, title = false}) => {
  return (
    <Row className={styles.prescriptionTableRow}>
      <Col>
        { isRender ?
          <PrescriptionTable
            prescription={prescription}
            title={title}
          />
          :
          null
        }
      </Col>
    </Row>
  )
}

export default TableContainer;