import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';
import UserModel from '../../../UserModel';

export default (app) => ({
    purchaseOrders: {
        path: '/purchase-orders',
        title: 'Purchase Orders',
        props: () => ({
            detailsPageRoute: '/purchase-orders/overview',
            formPageRoute: '/purchase-orders/add',
            formEditPageRoute: '/purchase-orders/edit',
            vendorDetailsPageRoute: '/vendors/overview',
            vendorDetailsPageRouteSuffix: '',
            orderDetailsPageRoute: '/orders',
            purchaseOrderDetailsPageRoute: 'purchase-orders',
            purchaseOrderDetailsPageRouteSuffix: 'overview',
        }),
        permission: ['purchase orders.index'],
        loader: () => import('@skunexus/purchase-orders-grid'),
    },
    purchaseOrderForm: {
        path: '/purchase-orders/add',
        title: 'Purchase Order Form',
        permission: ['purchase orders.create'],
        props: () => ({
            detailsPageRoute: '/purchase-orders/overview',
        }),
        loader: () => import('@skunexus/purchase-order-form'),
    },
    purchaseOrderEditForm: {
        path: '/purchase-orders/edit/:id',
        title: 'Purchase Order Edit Form',
        props: () => ({
            detailsPageRoute: '/purchase-orders/overview',
        }),
        permission: ['purchase orders.create'],
        loader: () => import('@skunexus/purchase-order-form'),
    },
    purchaseOrderOverview: {
        path: '/purchase-orders/overview/:id',
        title: 'Purchase Order Overview',
        permission: ['purchase orders.show'],
        props: () => ({
            formPageRoute: '/purchase-order/:purchaseOrderId/edit',
            productDetailsPageRoute: '/products/overview/:id',
            vendorProductDetailsPageRoute: '/vendor/products/:id?sku=:sku',
            vendorDetailsPageRoute: '/vendors/overview/:id',
            orderDetailsPageRoute: '/orders',
            printPageRoute: '/purchase-order/print',
            permissions: {
                allowedStatuses: app.general.getItem('modules.purchaseOrder.userStatuses'),
                viewAttributes: !UserModel.isVendor(),
                editAttributes: !UserModel.isVendor(),
                orderOnlyAdmin: !UserModel.isVendor(),
            },
        }),
        loader: () => import('@skunexus/purchase-order-overview'),
    },
    purchaseOrderPrint: {
        path: '/purchase-order/print/:id',
        title: 'Purchase Order Print',
        permission: ['purchase orders.show'],
        loader: () => import('@skunexus/purchase-order-print'),
    },
    purchaseOrderStatusesGrid: {
        path: app.settingsPurchaseOrdersTabs.getItem('statuses').link,
        title: app.settingsPurchaseOrdersTabs.getItem('statuses').title,
        permission: app.settingsPurchaseOrdersTabs.getItem('statuses').permission,
        loader: () => import('@skunexus/purchase-order-status-grid'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsPurchaseOrdersTabs.getAll())), { sub: true })(
                    Component,
                ),
            ),
    },
    purchaseOrderNumberGrid: {
        path: app.settingsPurchaseOrdersTabs.getItem('poNumber').link,
        title: app.settingsPurchaseOrdersTabs.getItem('poNumber').title,
        permission: app.settingsPurchaseOrdersTabs.getItem('poNumber').permission,
        loader: () => import('@skunexus/purchase-order-po-number-grid'),
        render: (Component) =>
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsPurchaseOrdersTabs.getAll())), { sub: true })(
                    Component,
                ),
            ),
    },
});
