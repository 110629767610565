//PAGE TITLE
export const SHIP_TITLE_PREFIX = 'Fulfillments > Ship';
export const PICKUP_TITLE_PREFIX = 'Fulfillments > Pickup';

//DOMAIN
export const SHIP_DOMAIN_NAME = 'shipmentFulfillment';
export const PICKUP_DOMAIN_NAME = 'inStoreFulfillment';

//WORKFLOW
export const SHIP_WORKFLOW_NAME = 'Shipment';
export const PICKUP_WORKFLOW_NAME = 'InStorePickup';

//COMMON ROUTES
export const ORDER_DETAILS_ROUTE = '/orders';
export const PRODUCTS_DETAILS_ROUTE = '/products/overview';
export const USERS_DETAILS_ROUTE = '/users';
export const SHIPMENT_DETAILS_ROUTE = '/shipment';

//SHIP ROUTES
export const SHIP_ROUTE_PREFIX = 'fulfillment-ship';
export const SHIP_ALL_ROUTE = `/${SHIP_ROUTE_PREFIX}/all`;
export const SHIP_BATCHING_ROUTE = `/${SHIP_ROUTE_PREFIX}/batching`;
export const SHIP_BATCH_PRINT_ROUTE = `/${SHIP_ROUTE_PREFIX}/batch-print`;
export const SHIP_CANCELLED_ROUTE = `/${SHIP_ROUTE_PREFIX}/cancelled`;
export const SHIP_DASHBOARD_ROUTE = `/${SHIP_ROUTE_PREFIX}/main`;
export const SHIP_DISPATCHED_ROUTE = `/${SHIP_ROUTE_PREFIX}/dispatched`;
export const SHIP_FINALIZED_ROUTE = `/${SHIP_ROUTE_PREFIX}/finalized`;
export const SHIP_GROUP_PICK_ROUTE = `/${SHIP_ROUTE_PREFIX}/group-pick`;
export const SHIP_HOSPITAL_ROUTE = `/${SHIP_ROUTE_PREFIX}/hospital`;
export const SHIP_PACK_ROUTE = `/${SHIP_ROUTE_PREFIX}/pack`;
export const SHIP_PICK_ROUTE = `/${SHIP_ROUTE_PREFIX}/pick`;
export const SHIP_PRINT_LOGS_ROUTE = `/${SHIP_ROUTE_PREFIX}/print-logs`;
export const SHIP_PRINTED_ROUTE = `/${SHIP_ROUTE_PREFIX}/printed`;
export const SHIP_WAVES_ROUTE = `/${SHIP_ROUTE_PREFIX}/waves`;
export const SHIP_WAVES_HISTORY_ROUTE = `/${SHIP_ROUTE_PREFIX}/waves-history`;

export const SHIP_DETAILS_ROUTE = `/${SHIP_ROUTE_PREFIX}/details`;
export const SHIP_GROUP_PICK_DETAILS_ROUTE = `/${SHIP_ROUTE_PREFIX}/group-pick-details`;
export const SHIP_PICKING_ROUTE = `/${SHIP_ROUTE_PREFIX}/picking`;
export const SHIP_GROUP_PICKING_ROUTE = `/${SHIP_ROUTE_PREFIX}/group-picking`;
export const SHIP_PACKING_ROUTE = `/${SHIP_ROUTE_PREFIX}/packing`;

//PICKUP ROUTES
export const PICKUP_ROUTE_PREFIX = 'fulfillment-pickup';
export const PICKUP_CANCELLED_ROUTE = `/${PICKUP_ROUTE_PREFIX}/cancelled`;
export const PICKUP_DASHBOARD_ROUTE = `/${PICKUP_ROUTE_PREFIX}/main`;
export const PICKUP_FINALIZED_ROUTE = `/${PICKUP_ROUTE_PREFIX}/finalized`;
export const PICKUP_GROUP_PICK_ROUTE = `/${PICKUP_ROUTE_PREFIX}/group-pick`;
export const PICKUP_HOSPITAL_ROUTE = `/${PICKUP_ROUTE_PREFIX}/hospital`;
export const PICKUP_PACK_ROUTE = `/${PICKUP_ROUTE_PREFIX}/pack`;
export const PICKUP_PICK_ROUTE = `/${PICKUP_ROUTE_PREFIX}/pick`;
export const PICKUP_READY_ROUTE = `/${PICKUP_ROUTE_PREFIX}/ready`;
export const PICKUP_WAVES_ROUTE = `/${PICKUP_ROUTE_PREFIX}/waves`;

export const PICKUP_DETAILS_ROUTE = `/${PICKUP_ROUTE_PREFIX}/details`;
export const PICKUP_GROUP_PICK_DETAILS_ROUTE = `/${PICKUP_ROUTE_PREFIX}/group-pick-details`;
export const PICKUP_PICKING_ROUTE = `/${PICKUP_ROUTE_PREFIX}/picking`;
export const PICKUP_GROUP_PICKING_ROUTE = `/${PICKUP_ROUTE_PREFIX}/group-picking`;
export const PICKUP_PACKING_ROUTE = `/${PICKUP_ROUTE_PREFIX}/packing`;

//SETTINGS ROUTES
export const WAVES_SETTINGS_ROUTE = '/settings/fulfillment/waves';
