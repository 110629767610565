import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.routes = app.routes || createConfigurableObject();
    app.routes.addItem('dashboard', {
        exact: true,
        path: '/:path(|dashboard)',
        title: 'Dashboard',
        props: () => (
            {
                tilesConfig: {
                    'New Orders': {
                        redirect: '/orders'
                    },
                    'In Fulfillment': {
                        redirect: '/fulfillment-ship/main'
                    },
                    'In Picking': {
                        redirect: '/fulfillment-ship/pick'
                    },
                    'Shipped Today': {
                        redirect: '/fulfillment-ship/dispatched'
                    }
                }
            }
        ),
        loader: () => import('@skunexus/dashboard'),
    });
}