import React from 'react';

import noop from 'lodash/noop';
import reduce from 'lodash/reduce';

import { dynamicModule } from '@skunexus/redux';
import { provideRouteFactory } from '@skunexus/utils';

import app from './config';

const config = app.general.getAll();

export function createRoute({
  render,
  ...config
}) {
  const storeSelector = state => state[config.name] || {};
  const Route = provideRouteFactory(config.props);
  const Component = dynamicModule(config);

  return (
    <Route
      exact
      key={ config.name }
      layout={ config.mainLayout }
      path={ config.path }
      title={ config.title }
      component={ render ? render(Component) : Component }
      storeSelector={ storeSelector }
      { ...config }
    />
  );
}

export function createRoutes(routes, mainLayout) {
  return reduce(routes, (result, value, key) => {
    const { props = noop } = value;
    const createProps = (state, routeProps) => (
      {
        ...props(state, routeProps),
        ...config.modules[value.name],
      }
    );
    result.push(createRoute({
      mainLayout,
      ...value,
      props: createProps,
    }));

    return result;
  }, []);
}
