import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.general = app.general || createConfigurableObject();
    app.general.addItem('name', 'Skunexus');
    app.general.addItem('smartlook', {
        enabled: true,
        id: '0a6c03c16d4bd878156cc154ac3fadac24599119',
    });
    app.general.addItem('modules', {
        fulfillment: {
            batchEnable: false,
        },
        fulfillmentShipGroupPicking: {
            forceTopDownPicking: true,
        },
    });
};
