import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';


export default app => ({
    queueFailedJobs: {
        name: 'queueFailedJobs',
        path: app.queueTabs.getItem('failed').link,
        title: app.queueTabs.getItem('failed').title,
        permission: app.queueTabs.getItem('failed').permission,
        props: () => ({}),
        loader: () => import('@skunexus/queue-failed-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.queueTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    queueSpawnedJobs: {
        name: 'queueSpawnedJobs',
        path: app.queueTabs.getItem('spawned').link,
        title: app.queueTabs.getItem('spawned').title,
        permission: app.queueTabs.getItem('spawned').permission,
        props: () => ({}),
        loader: () => import('@skunexus/queue-spawned-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.queueTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
});