export const VENDORS_PREFIX = 'vendors';
export const VENDORS_GRID = `/${VENDORS_PREFIX}`;
export const VENDORS_DETAILS = `/${VENDORS_PREFIX}/overview`;
export const VENDORS_FORM = `/${VENDORS_PREFIX}/:action(add|edit)/:id?`;
export const VENDORS_CREATE = `/${VENDORS_PREFIX}/add`;
export const VENDORS_EDIT = `/${VENDORS_PREFIX}/edit`;

export const VENDOR_PREFIX = 'vendor';
export const VENDOR_ADDRESSES_GRID = `/${VENDOR_PREFIX}/addresses`;
export const VENDOR_ADDRESSES_FORM = `/${VENDOR_PREFIX}/addresses/:vendorId/:action(add|edit)/:id?`;
export const VENDOR_ADDRESSES_ADD = `/${VENDOR_PREFIX}/addresses/:vendorId/add`;
export const VENDOR_ADDRESSES_EDIT = `/${VENDOR_PREFIX}/addresses/:vendorId/edit/:id`;
export const VENDOR_PRODUCTS_GRID = `/${VENDOR_PREFIX}/products`;
export const VENDOR_CONTACTS_GRID = `/${VENDOR_PREFIX}/contacts`;
export const VENDOR_CONTACTS_FORM = `/${VENDOR_PREFIX}/contacts/:vendorId/:action(add|edit)/:id?`;
export const VENDOR_CONTACTS_ADD = `/${VENDOR_PREFIX}/contacts/:vendorId/add`;
export const VENDOR_CONTACTS_EDIT = `/${VENDOR_PREFIX}/contacts/:vendorId/edit/:id`;
export const VENDOR_PURCHASE_ORDERS_GRID = `/${VENDOR_PREFIX}/purchase-orders`;
export const VENDOR_REPORTS = `/${VENDOR_PREFIX}/reports`;

//COMMON ROUTES
export const PRODUCT_OVERVIEW = '/products/overview';
export const PURCHASE_ORDER_ADD = '/purchase-orders/add';
export const PURCHASE_ORDER_OVERVIEW = '/purchase-orders/overview/:id';
export const PURCHASE_ORDERS_GRID = '/purchase-orders';
export const ORDERS_GRID = '/orders';
