import React, {Component} from 'react';
import {provideRouteFactory} from '@skunexus/utils';
import { actions } from '@skunexus/auth';

/*
  Setup below
*/

const path = '/logout';

class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        return null;
    }
}

const storeSelector = state => state;

/*
  Setup above
*/

const dispatchProps = {
    logout: actions.logout.TRIGGER,
};

const Route = provideRouteFactory(null, dispatchProps);

export default props => {
    return (
        <Route
            {...props}
            path={path}
            component={Logout}
            storeSelector={storeSelector}
        />
    );
};
