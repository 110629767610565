import values from 'lodash/values';

export default (app) => {
    const tabs = app.receiveInventoryTabs.getAll();

    const routers = {
        receiveInventoryEnterLocationDetails: {
            path: '/receive-inventory-enter-location/:id/:stockId',
            title: 'Receive Inventory - Enter Location Details',
            permission: ['purchase orders - stocks.receive'],
            props: () => ({
                indexPageRoute: '/receive-inventory-main',
                descriptorTypes: {
                    1: 'string',
                    2: 'number',
                    3: 'select',
                },
            }),
            loader: () => import('@skunexus/ri-enter-location-details'),
        },
        receiveInventoryJai: {
            path: '/receive-inventory-just-adding',
            title: 'Receive Inventory - Just Adding Inventory',
            permission: ['inventory - stocks.create', 'inventory - stocks.adjust'],
            props: () => ({
                indexPageRoute: '/receive-inventory-main',
                productDetailsPageRoute: '/products/overview',
            }),
            loader: () => import('@skunexus/receive-inventory-just-adding'),
        },
        receiveInventoryMain: {
            path: '/receive-inventory/main',
            title: 'Receive Inventory',
            permission: ['purchase orders - stocks.receive'],
            props: () => ({
                productDetailsPageRoute: '/products/overview',
                formPageRoute: '/receive-inventory-enter-location',
                jaiRoute: '/receive-inventory-just-adding',
                receiveFromPoRoute: '/receive-inventory-purchase-order',
                itemsReceiveRoute: '/receive-inventory/items',
                tabs: values(tabs),
            }),
            loader: () => import('@skunexus/receive-inventory'),
        },
        receiveInventoryItems: {
            path: '/receive-inventory/items',
            title: 'Items Received',
            permission: ['receive inventory.put away'],
            props: () => ({
                productDetailsPageRoute: '/products/overview',
                putAwayPageRoute: '/receive-inventory/put-away',
                tabs: values(tabs),
            }),
            loader: () => import('@skunexus/receive-inventory-items-received'),
        },
        receiveInventoryPutAway: {
            path: '/receive-inventory/put-away',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                tabs: values(tabs),
                putAwayMainRoute: '/receive-inventory/put-away-main',
            }),
            loader: () => import('@skunexus/put-away'),
        },
        receiveInventoryPutAwayMain: {
            path: '/receive-inventory/put-away-main/:id',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                finalizedRoute: '/receive-inventory-finalized',
                putAwayRoute: '/receive-inventory/put-away',
                putAwayFinalRoute: '/receive-inventory/put-away-final',
            }),
            loader: () => import('@skunexus/put-away-main'),
        },
        receiveInventoryPutAwayFinal: {
            path: '/receive-inventory/put-away-final',
            title: 'Put Away',
            permission: ['receive inventory.put away'],
            props: () => ({
                tabs: values(tabs),
                detailsPageRoute: '/receive-inventory/put-away-final-details',
            }),
            loader: () => import('@skunexus/put-away-final'),
        },
        receiveInventoryPutAwayFinalDetails: {
            path: '/receive-inventory/put-away-final-details/:id',
            title: 'Put Away Details',
            permission: ['receive inventory.put away'],
            loader: () => import('@skunexus/put-away-final-details'),
        },
    };

    return routers;
};
