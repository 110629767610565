import {
    VENDORS_DETAILS,
    VENDOR_ADDRESSES_GRID,
    VENDOR_PRODUCTS_GRID,
    VENDOR_CONTACTS_GRID,
    VENDOR_PURCHASE_ORDERS_GRID,
    VENDOR_REPORTS,
} from './consts';

export default (app) => ({
    overview: {
        id: 'Overview',
        label: 'Overview',
        title: 'Vendor Overview',
        link: `${VENDORS_DETAILS}/:vendorId`,
        permission: 'vendors.show',
        order: 10,
    },
    addresses: {
        id: 'Addresses',
        label: 'Addresses',
        title: 'Vendor Addresses',
        link: `${VENDOR_ADDRESSES_GRID}/:vendorId`,
        permission: 'vendors - addresses.index',
        order: 20,
    },
    products: {
        id: 'Products',
        label: 'Products',
        title: 'Vendor Products',
        link: `${VENDOR_PRODUCTS_GRID}/:vendorId`,
        permission: 'vendors - products.index',
        order: 30,
    },
    contacts: {
        id: 'Contacts',
        label: 'Contacts',
        title: 'Vendor Contacts',
        link: `${VENDOR_CONTACTS_GRID}/:vendorId`,
        permission: 'vendors - contacts.index',
        order: 50,
    },
    purchaseOrders: {
        id: 'Purchase Orders',
        label: 'Purchase Orders',
        title: 'Vendor Purchase Orders',
        link: `${VENDOR_PURCHASE_ORDERS_GRID}/:vendorId`,
        permission: 'purchase orders.index',
        order: 60,
    },
    reportsAndPayouts: {
        id: 'Reports & Payouts',
        label: 'Reports & Payouts',
        title: 'Vendor Reports & Payouts',
        link: `${VENDOR_REPORTS}/:vendorId`,
        permission: 'vendors.show',
        order: 70,
    },
});
