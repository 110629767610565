import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils';

import routes from './routes';
import vendorRoutes from './vendorRoutes';
import tabs from './tabs';
import sideMenuItem from './sideMenu';
import vendorSideMenuItems from './vendorSideMenuItems';

export default (app) => {
    app.vendorTabs = app.vendorTabs || createConfigurableObject();
    app.vendorTabs.addMany(tabs(app));

    app.settingsVendorTabs = app.settingsVendorTabs || createConfigurableObject();
    app.settingsVendorTabs.addItem('dashboard', {
        id: 'Vendor Dashboard',
        label: 'Vendor Dashboard',
        title: 'Vendor Dashboard',
        link: '/settings/vendors/dashboard',
        permission: 'settings - general.save',
    });

    app.settingsTabs.addItem('vendors', {
        label: 'Vendors',
        link: () => firstAllowedLink(values(app.settingsVendorTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsVendorTabs.getAll())),
        order: 13,
    });

    app.routes.addMany(routes(app));
    vendorRoutes(app);

    app.sideMenuItems.addItem('vendors', sideMenuItem(app));
    app.vendorSideMenuItems.addMany(vendorSideMenuItems(app));
};
