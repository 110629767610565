export default app => ({
    warehouses: {
        id: 'Warehouses',
        label: 'Warehouses',
        title: 'Warehouses',
        link: '/settings/warehouses/warehouses',
        permission: 'warehouses.index',
        order: 10
    },
    descriptors: {
        id: 'Descriptors',
        label: 'Descriptors',
        title: 'Descriptors',
        link: '/settings/warehouses/descriptors',
        permission: 'settings - descriptors.index',
        order: 20
    },
    descriptorTemplates: {
        id: 'Descriptor Templates',
        label: 'Descriptor Templates',
        title: 'Descriptor Templates',
        link: '/settings/warehouses/descriptor-templates',
        permission: 'settings - description templates.index',
        order: 30
    },
    carts: {
        id: 'Carts',
        label: 'Carts',
        title: 'Carts',
        link: '/settings/warehouses/carts',
        permission: 'carts.index',
        order: 40
    },
    totes: {
        id: 'Totes',
        label: 'Totes',
        title: 'Totes',
        link: '/settings/warehouses/totes',
        permission: 'totes.index',
        order: 41
    },
    configuration: {
        id: 'Warehouse Configurations',
        label: 'Warehouse Configurations',
        title: 'Warehouse Configurations',
        link: '/settings/warehouses/warehouse-configurations',
        permission: 'settings - warehouses.manage descriptor values order',
        order: 50
    },
})
