import { createSelector } from 'reselect';
import { selectors as fromApp } from '@skunexus/utils';
import createConfigurableObject from '../../../utils/configurationObject';

const getApp = state => state.app;

export default app => {
    app.selectors = app.selectors || createConfigurableObject();
    app.selectors.addMany({
        getPageName: createSelector([getApp], fromApp.getPageName),
        getIsSidebarMenuOpen: createSelector([getApp], fromApp.getIsSidebarMenuOpen),
        getAuth: state => state.auth,
        getNotifications: state => state.notifications,
        getFulfillmentMainTabs: state => state.fulfillmentMainTabs,
    })
}