export default app => ({
    customerAddressForm: {
        path: '/customer/:id/customer-address-form/:addressId?',
        title: 'Customer Address Form',
        permission: ['customers - addresses.create', 'customers - addresses.update'],
        loader: () => import('@skunexus/customer-address-form'),
        props: () => (
            {
                indexPageRoute: '/customer',
                detailsPageRoute: '/customer',
            }
        )
    },
    customersForm: {
        path: '/customer-form/:id?',
        title: 'Customer Form',
        permission: ['customers.create', 'customers.update'],
        loader: () => import('@skunexus/customer-form'),
        props: () => (
            {
                indexPageRoute: '/customers',
                detailsPageRoute: '/customer'
            }
        ),
    },
    customerDetails: {
        path: '/customer/:id',
        title: 'Customer Details',
        permission: ['customers.show'],
        loader: () => import('@skunexus/customer-details'),
        props: () => (
            {
                indexPageRoute: '/customer',
                custFormRoute: '/customer-form',
                orderViewRoute: '/orders',
                addressFormRoute: '/customer/:customerId/customer-address-form',
            }
        ),
    },
    customers: {
        path: '/customers',
        title: 'Customers',
        permission: ['customers.index'],
        loader: () => import('@skunexus/customers-grid'),
        props: () => (
            {
                detailsPageRoute: '/customer',
                formPageRoute: '/customer-form'
            }
        ),
    }
})