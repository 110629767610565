import React from 'react';
import omit from 'lodash/omit';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import { PrintLayout } from '@skunexus/component-catalog';

import {
    PICKUP_TITLE_PREFIX,
    PICKUP_DOMAIN_NAME,
    PICKUP_WORKFLOW_NAME,
    PICKUP_FINALIZED_ROUTE,
    PICKUP_GROUP_PICK_ROUTE,
    PICKUP_PACK_ROUTE,
    PICKUP_PICK_ROUTE,
    PICKUP_READY_ROUTE,
    PICKUP_DETAILS_ROUTE,
    PICKUP_GROUP_PICK_DETAILS_ROUTE,
    PICKUP_PICKING_ROUTE,
    PICKUP_GROUP_PICKING_ROUTE,
    PICKUP_PACKING_ROUTE,
    ORDER_DETAILS_ROUTE,
    PRODUCTS_DETAILS_ROUTE,
    USERS_DETAILS_ROUTE,
    SHIPMENT_DETAILS_ROUTE,
} from './consts';
import MainTabs from './components/PickupFulfillmentMainTabsLayout';

const openStatus = {
    operator: 'neq',
    value: 'picked',
};

export default (app) => {
    const tabs = app.pickupFulfillmentTabs.getAll();

    const routers = {
        pickupDetails: {
            path: `${PICKUP_DETAILS_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Details`,
            permission: ['fulfillments.show'],
            props: () => ({
                printRoute: `${PICKUP_DETAILS_ROUTE}/print`,
                pickingRoute: PICKUP_PICKING_ROUTE,
                packingRoute: PICKUP_PACKING_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                finalizedRoute: PICKUP_FINALIZED_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-details'),
        },
        pickupDetailsPrint: {
            path: `${PICKUP_DETAILS_ROUTE}/print/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Details > Print Preview`,
            layout: PrintLayout,
            permission: ['fulfillments.show'],
            props: () => ({
                printRoute: `${PICKUP_DETAILS_ROUTE}/print`,
                pickingRoute: PICKUP_PICKING_ROUTE,
                packingRoute: PICKUP_PACKING_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                finalizedRoute: PICKUP_FINALIZED_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-details'),
        },
        pickupGroupPickDetails: {
            path: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Group Pick Details`,
            permission: ['fulfillments.picking'],
            props: () => ({
                printRoute: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/print`,
                pickingRoute: PICKUP_GROUP_PICKING_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-group-pick-details'),
        },
        pickupGroupPickDetailsPrint: {
            path: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/print/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Group Pick Details > Print Preview`,
            layout: PrintLayout,
            permission: ['fulfillments.picking'],
            props: () => ({
                printRoute: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/print`,
                pickingRoute: PICKUP_GROUP_PICKING_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-group-pick-details'),
        },
        pickupGroupPicking: {
            path: `${PICKUP_GROUP_PICKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Group Picking`,
            permission: ['fulfillments.picking'],
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                printPageRoute: `${PICKUP_GROUP_PICK_DETAILS_ROUTE}/print`,
                packPageRoute: PICKUP_PACK_ROUTE,
                shipPickListRoute: PICKUP_GROUP_PICK_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                domain: PICKUP_DOMAIN_NAME,
            }),
            loader: () => import('@skunexus/fulfillment-group-picking'),
        },
        pickupPicking: {
            path: `${PICKUP_PICKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Picking`,
            permission: ['fulfillments.picking'],
            props: () => ({
                printPageRoute: `${PICKUP_DETAILS_ROUTE}/print`,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                packPageRoute: PICKUP_PACKING_ROUTE,
                shipPickListRoute: PICKUP_PICK_ROUTE,
                productDetailsRoute: PRODUCTS_DETAILS_ROUTE,
                userDetailsPageRoute: USERS_DETAILS_ROUTE,
                domain: PICKUP_DOMAIN_NAME,
            }),
            loader: () => import('@skunexus/fulfillment-picking'),
        },
        pickupPacking: {
            path: `${PICKUP_PACKING_ROUTE}/:id`,
            title: `${PICKUP_TITLE_PREFIX} > Packing`,
            permission: ['fulfillments.packing'],
            props: () => ({
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                packPageRoute: PICKUP_PACK_ROUTE,
                pickPageRoute: PICKUP_PICKING_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-packing'),
        },
    };

    if (tabs.finalized) {
        routers.pickupFinalized = {
            path: tabs.finalized.link,
            title: tabs.finalized.title,
            permission: tabs.finalized.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-finalized-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    state: 'finalized',
                },
            }),
            loader: () => import('@skunexus/fulfillment-fulfilled'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.groupPick) {
        routers.pickupGroupPick = {
            path: tabs.groupPick.link,
            title: tabs.groupPick.title,
            permission: tabs.groupPick.permission,
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-group-pick'),
            render: (Component) => (props) => (
                <MainTabs showAssignUserFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.main) {
        const pickupMainCustomGridSections = (config) => {
            const newConfig = cloneDeep(config);
            newConfig.sections = {
                ...newConfig.sections,
                pick: {
                    ...newConfig.sections.pick,
                    path: PICKUP_PICK_ROUTE,
                },
                pack: {
                    ...newConfig.sections.pack,
                    path: PICKUP_PACK_ROUTE,
                },
                ready: {
                    title: 'Ready For Pickup',
                    path: PICKUP_READY_ROUTE,
                    order: 30,
                },
                finalized: {
                    title: 'Finalized',
                    path: PICKUP_FINALIZED_ROUTE,
                    order: 40,
                },
            };
            newConfig.sections = omit(newConfig.sections, ['dispatch', 'fulfilled']);
            newConfig.domain = PICKUP_DOMAIN_NAME;
            return newConfig;
        };

        routers.pickupMain = {
            path: tabs.main.link,
            title: tabs.main.title,
            permission: tabs.main.permission,
            props: () => ({
                customGridSections: pickupMainCustomGridSections,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/ship-main'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} showAssignUserFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.pack) {
        routers.pickupPack = {
            path: tabs.pack.link,
            title: tabs.pack.title,
            permission: tabs.pack.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                packingPageRoute: PICKUP_PACKING_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-pack'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.pick) {
        const customGridData = (data) => {
            data.columns.push({
                field: 'pickup_date',
                label: 'Pickup Date',
                type: 'date',
                isFilterable: true,
                isSortable: true,
                order: 50,
            });

            remove(data.columns, (column) => {
                return column.field === 'destination';
            });

            data.columns = data.columns.map((column) => {
                if (column.field === 'fulfillFrom.label') {
                    column.label = 'Pickup Store';
                }
                return column;
            });

            return data;
        };

        routers.pickupPick = {
            path: tabs.pick.link,
            title: tabs.pick.title,
            permission: tabs.pick.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_PICKING_ROUTE,
                groupPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-pick-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    workflow: PICKUP_WORKFLOW_NAME,
                },
                customGridData,
            }),
            loader: () => import('@skunexus/fulfillment-pick'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.waves) {
        routers.pickupWaves = {
            path: tabs.waves.link,
            title: tabs.waves.title,
            permission: tabs.waves.permission,
            props: () => ({
                detailsPageRoute: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                statusOptions: [
                    { value: openStatus, label: 'All' },
                    { value: 'not_picked', label: 'Not Picked' },
                    { value: 'partial_picked', label: 'Partial Picked' },
                ],
                defaultStatus: openStatus,
                gridConfig: {
                    id: 'fulfillment-pickup-wave-grid',
                    domain: PICKUP_DOMAIN_NAME,
                    workflow: PICKUP_WORKFLOW_NAME,
                },
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.ready) {
        routers.pickupReady = {
            path: tabs.ready.link,
            title: tabs.ready.title,
            permission: tabs.ready.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
            }),
            loader: () => import('@skunexus/pickup-ready'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.cancelled) {
        routers.pickupCancelled = {
            path: tabs.cancelled.link,
            title: tabs.cancelled.title,
            permission: tabs.cancelled.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                detailsPageRoute: PICKUP_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-cancelled-grid',
                    domain: PICKUP_DOMAIN_NAME,
                },
            }),
            loader: () => import('@skunexus/fulfillment-cancelled'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.hospital) {
        routers.pickupHospital = {
            path: tabs.hospital.link,
            title: tabs.hospital.title,
            permission: tabs.hospital.permission,
            props: () => ({
                orderDetailsPageRoute: ORDER_DETAILS_ROUTE,
                gridConfig: {
                    id: 'fulfillment-pickup-hospital-grid',
                    domain: PICKUP_DOMAIN_NAME,
                },
            }),
            loader: () => import('@skunexus/fulfillment-hospital'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }

    return routers;
};
