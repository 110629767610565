export default app => ({
    orderAttributes: {
        id: 'Order Attributes',
        label: 'Order Attributes',
        title: 'Order Attributes',
        link: '/settings/orders/attributes',
        permission: [
            'orders - custom attributes.field create',
            'orders - custom attributes.field update',
            'orders - custom attributes.field delete',
        ],
        order: 10
    },
    userAttributes: {
        id: 'User Attributes',
        label: 'User Attributes',
        title: 'User Attributes',
        link: '/settings/users/attributes',
        permission: [
            'users - custom attributes.field create',
            'users - custom attributes.field update',
            'users - custom attributes.field delete',
        ],
        order: 20
    },
    productAttributes: {
        id: 'Product Attributes',
        label: 'Product Attributes',
        title: 'Product Attributes',
        link: '/settings/products/attributes',
        permission: [
            'products - custom attributes.field create',
            'products - custom attributes.field update',
            'products - custom attributes.field delete',
        ],
        order: 30
    },
    customerAttributes: {
        id: 'Customer Attributes',
        label: 'Customer Attributes',
        title: 'Customer Attributes',
        link: '/settings/customers/attributes',
        permission: [
            'customers - custom attributes.field create',
            'customers - custom attributes.field update',
            'customers - custom attributes.field delete',
        ],
        order: 40
    },
    vendorAttributes: {
        id: 'Vendor Attributes',
        label: 'Vendor Attributes',
        title: 'Vendor Attributes',
        link: '/settings/vendors/attributes',
        permission: [
            'vendors - custom attributes.field create',
            'vendors - custom attributes.field update',
            'vendors - custom attributes.field delete',
        ],
        order: 50
    },
    vendorProductAttributes: {
        id: 'Vendor Product Attributes',
        label: 'Vendor Product Attributes',
        title: 'Vendor Product Attributes',
        link: '/settings/vendor-products/attributes',
        permission: [
            'vendors - custom attributes.field create',
            'vendors - custom attributes.field update',
            'vendors - custom attributes.field delete',
        ],
        order: 60
    },
    warehouseAttributes: {
        id: 'Warehouse Attributes',
        label: 'Warehouse Attributes',
        title: 'Warehouse Attributes',
        link: '/settings/warehouses/attributes',
        permission: [
            'warehouses - custom attributes.field create',
            'warehouses - custom attributes.field update',
            'warehouses - custom attributes.field delete',
        ],
        order: 80
    },
    poAttributes: {
        id: 'Purchase Order Attributes',
        label: 'Purchase Order Attributes',
        title: 'Purchase Order Attributes',
        link: '/settings/purchase-orders/attributes',
        permission: [
            'purchase orders - custom attributes.field create',
            'purchase orders - custom attributes.field update',
            'purchase orders - custom attributes.field delete',
        ],
        order: 90
    },
})