import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.vendorHeaderMenuItems = app.vendorHeaderMenuItems || createConfigurableObject();
    app.vendorHeaderMenuItems.addMany({
        overview: vendorId => ({
            label: 'Vendor',
            link: `/vendors/${vendorId}/overview`,
        }),
        logout: {
            hasBorder: true,
            label: 'Logout',
            link: '/logout',
        }
    })
}