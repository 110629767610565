import forEach from 'lodash/forEach';
import values from 'lodash/values';
import { selectors } from '@skunexus/utils';

import UserModel from '../../../UserModel';
import { filterAccessibleItems } from '../../../utils';
import createConfigurableObject from '../../../utils/configurationObject';

const routesToCopy = [
    'vendorContactFormAdd',
    'vendorContactFormEdit',
    'vendorContacts',
    'vendorOverview',
    'vendorProducts',
    'vendorPurchaseOrders',
    'vendorFormEdit',
];

export default app => {
    const vendorTabs = app.vendorTabs.getAll();
    app.vendorRoutes = app.vendorRoutes || createConfigurableObject();

    if (vendorTabs.reportsAndPayouts) {
        app.vendorRoutes.addItem('vendorReports', {
            path: vendorTabs.reportsAndPayouts.link,
            title: vendorTabs.reportsAndPayouts.title,
            permission: vendorTabs.reportsAndPayouts.permission,
            props: state => ({
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(vendorTabs)),
                idNameMap: selectors.getIDNameMap(state.app)
            }),
            loader: () => {
                return import('@skunexus/vendor-reports-payouts').then(ModuleConfig => {
                    return {
                        ...ModuleConfig,
                        Module: ModuleConfig.VendorModule
                    };
                });
            }
        });
    }

    forEach(routesToCopy, routeName => {
        const route = Object.assign( {}, app.routes.getItem(routeName));
        if (route) {
            app.vendorRoutes.addItem(routeName, route);
        }
    });


    const vendorOverviewRouter = app.vendorRoutes.getItem('vendorOverview');
    if (vendorOverviewRouter) {

        const vendorOverviewProps = vendorOverviewRouter.props;
        vendorOverviewRouter.props = state => (
            {
                ...vendorOverviewProps(state),
                tabs: [],
            }
        );

        app.vendorRoutes.changeItem('vendorOverview', vendorOverviewRouter)
    }
}