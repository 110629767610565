import React from 'react';
import { DetailsCardBarcode } from '@skunexus/component-catalog';
import styles from "../styles.module.css";
import { Row, Col } from 'reactstrap';
import {getBarcodeLabel, getRowValue, parsePrescription} from "../utils/helpers";
import TableContainer from "../TableContainer"

const ItemsList = ({barcodes : {items, storeNo, typeCode}, containsPrescriptionGlasses}) => {

  return (
    <section className={styles.barcodeContainer}>
      <h3>Items</h3>

      { items.map(({mve_order_id, id, sku, name, prescription}) => (
        <div
          className={containsPrescriptionGlasses ? styles.withPrescription : styles.withoutPrescription}
          key={id}>
          <Row className={styles.itemList}>
            <Col>
              { getRowValue('SKU', sku) }
              { getRowValue('Brand', name) }
            </Col>

            <Col className={styles.barcodeItem}>
              {mve_order_id ?
                  <DetailsCardBarcode data={getBarcodeLabel(storeNo, mve_order_id)} type={typeCode} />
                  :
                  <h2>NOT SENT TO MVE</h2>
              }
            </Col>

          </Row>

          <TableContainer
            isRender={containsPrescriptionGlasses}
            prescription={parsePrescription(prescription)}
          />
        </div>
      )) }
    </section>

  )
}

export default ItemsList;