import React from 'react'

function getRowValue(rowName, value = null) {
  return (
    <p>
      <span>{rowName}</span>: {value}
    </p>
  )
}

function getAddressDetails(...values) {
  return values.map((el, i) =>
    <li key={el + i}>{el}</li>
  )
}

function findCustomFieldValue(data, fieldName) {
  const customField = data.find(el => el.custom_field_id === fieldName);

  if (!customField) {
    return false;
  }

  if (typeof customField.value === 'number' && customField.value <= 1) {
    return !!customField;
  }

  return customField.value;
}

function getTdValue (val) {
  if (!val && val !== 0) return '-';
  return val;
}

function parsePrescription(prescription) {
  if (!prescription) return null;

  const prn = JSON.parse(prescription)

  return {
    lenses: {
      od: {
        clensodbasecrv1: prn.clensodbasecrv1,
        clensoddiam1: prn.clensoddiam1,
        od_add: prn.od_add,
        od_multifocal: prn.od_multifocal,
        odaxis: prn.odaxis,
        odcylindertext: prn.odcylindertext,
        odspheretext: prn.odspheretext
      },
      os: {
        clensosbasecrv1: prn.clensosbasecrv1,
        clensosdiam1: prn.clensosdiam1,
        os_add: prn.os_add,
        os_multifocal: prn.os_multifocal,
        osaxis: prn.osaxis,
        oscylindertext: prn.oscylindertext,
        osspheretext: prn.osspheretext
      }
    }
  }
}

function getBarcodeLabel (storeNumber, orderId) {
  return storeNumber.toString() + orderId.toString() + '00'
}
export {
  getRowValue,
  getAddressDetails,
  findCustomFieldValue,
  getTdValue,
  parsePrescription,
  getBarcodeLabel
}