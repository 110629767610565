import axios from "axios";
import { constants, gql } from "@skunexus/utils";
import { helpers } from '@skunexus/custom-fields';
import {findCustomFieldValue} from "../components/InvoicePrintLayout/utils/helpers";

const API_PATH = constants.CONFIG.API_PATH;

export async function getPrescriptionItemData(id) {
  let response = await fetch(`${API_PATH}/orders/${id}/prescription`);

  return await response.json()
};

export async function getInvoiceData(setDataFn, id) {
  try {
    let request = await fetchInvoice(id);
    let data = await request.data;

    const { data: {order: {details}} } = data;
    const { customValues, items, id: invoiceId, label, shippingAddress, billingAddress } = details;

    const storeNo = findCustomFieldValue(customValues, "store_no");
    const containsPrescriptionGlasses = findCustomFieldValue(customValues, "contains_prescription_glasses");
    const containsLenses = findCustomFieldValue(customValues, "contains_lenses");

    const invoiceData = {
      shippingAddress: {...shippingAddress},
      billingAddress: {...billingAddress},
      barcodes: {
        items: [...items],
        storeNo,
        typeCode: '3of9'
      },
      containsPrescriptionGlasses,
      containsLenses,
      invoiceId,
      label
    }

    if (containsLenses) {
      let request = await getPrescriptionItemData(invoiceData.invoiceId);
      let data = await request;

      setDataFn({
        ...invoiceData,
        lensesPrescription: {
          lenses: {...data.prescription.lenses},
        }
      });

      window.print();
      return;
    }

    setDataFn(invoiceData);
    window.print();
  } catch (e) {
    console.log('catch', e)
  }
}


export function fetchInvoice(id) {
  const query = `query V1Queries { order { details(id: \"${ id }\" ) 
    { 
      id
      label
      customValues {
        custom_field_id
        value
      }
      customValues {
        custom_field_id
        value
      }
      shippingAddress {
        addressVerification {
            id
            address
            suggestions
            updated_at
            suggestions
            address_key
        }
        first_name
        last_name
        company
        street1
        street2
        city
        region
        postcode
        country
      }
      billingAddress {
        first_name
        last_name
        company
        street1
        street2
        city
        region
        postcode
        country
      }
      items {
        barcodes {
          barcodeValue
          barcodeType
        }
        id
        name
        prescription
        sku
        mve_order_id
      }
    }}}`;

  return axios.post(`${API_PATH}/query`, { query });
}

export function fetchDetails(id, domain) {
  return gql.getGraphQLDetails({
    url: `${API_PATH}/query`,
    config: {
      domain,
      detailsField: "details"
    },
    fields: `
      id
      label
      barcodeValue
      ${helpers.apiHelper.customValuesField}
      created_at
      updated_at
      current_state
      relatedOrder {
        label
        id
      }
      assignedUser {
        id
        label
      }
      assignedCart {
        id
        label
      }
      fulfillFrom {
        id
        label
      }
      items {
        id
        product {
          id
          name
          sku
        }
        barcodes {
          barcodeType
          barcodeValue
        }
        quantity
        location {
          label
        }
      }
    `,
    payload: { id }
  });
}

export function moveToPack(id) {
  return axios.post(`${API_PATH}/fulfillment/${id}/transition/pack`);
}

export function fetchCarts(fulfillFrom) {
  return gql.getGraphQLGrid({
    url: `${API_PATH}/query`,
    config: {
      domain: 'cart',
    },
    gridQueryPart: `grid(
      filter: {
        active: { operator: like, value: "%1%" }
        fulfillFrom: {
          label: { operator: like, value: "%${fulfillFrom}%" }
        }
      }
    ) {
      rows { id, label, barcode_value }
    }`
  });
}

export function assignCartId(id, cartId) {
  return axios.put(`${API_PATH}/fulfillment/${id}/cart`, {
    id: cartId
  });
}

export function fetchUsers() {
  return gql.getGraphQLGrid({
    url: `${API_PATH}/query`,
    config: {
      domain: 'user',
    },
    gridQueryPart: `grid { rows {
      id,
      label: name,
      first_name,
      last_name,
    } }`
  });
}

export function assignUser(id, userId) {
  return axios.put(`${API_PATH}/fulfillment/${id}/user`, {id: userId})
}
