import { createMainLayout } from '../utils';
import values from 'lodash/values';
import app from '../config';

const mainLayoutProps = () => ({
    headerMenuItems: values(app.headerMenuItems.getAll()),
    sideMenuItems: values(app.sideMenuItems.getAll()).sort(
        ({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB,
    ),
});

export default createMainLayout(mainLayoutProps);
