import React from 'react';
import { Provider } from 'react-redux';
import { subspaced, parentSpaced, ParentSpaceProvider } from 'react-redux-subspace';
import { ConnectedRouter } from 'connected-react-router';

import Auth from '@skunexus/auth';
import Notifications from '@skunexus/notification';
import store, { history } from './store';
import PortalSwitch from './PortalSwitch';
import SmartLookInit from './smartLook/SmartLookInit';
import SmartLookForLogged from './smartLook/SmartLookForLogged';

import app from './config';

const NotificationsModule = subspaced(app.selectors.getItem('getNotifications'))(Notifications);
const AuthModule = subspaced(app.selectors.getItem('getAuth'))(Auth);
const PortalSwitchModule = parentSpaced()(PortalSwitch);

const App = () => (
    <Provider store={store}>
        <SmartLookInit />
        <ConnectedRouter history={history}>
            <NotificationsModule />
            <AuthModule {...app.general.getAll()} history={history}>
                <SmartLookForLogged />
                <ParentSpaceProvider>
                    <PortalSwitchModule />
                </ParentSpaceProvider>
            </AuthModule>
        </ConnectedRouter>
    </Provider>
);

export default App;
