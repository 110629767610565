import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, filterAccessibleItems, combinePermissions } from '../../../utils';

import sideMenuItem from './sideMenu';

export default app => {
    app.settingsGlobalTabs = app.settingsGlobalTabs || createConfigurableObject();
    app.settingsGlobalTabs.addItem('currency', {
        id: 'Currency',
        label: 'Currency',
        title: 'Global Currency',
        link: '/settings/global/currency',
        permission: 'settings - general.save',
    });

    app.settingsTabs = app.settingsTabs || createConfigurableObject();
    app.settingsTabs.addItem('global', {
        label: 'Global',
        link: () => firstAllowedLink(values(app.settingsGlobalTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsGlobalTabs.getAll())),
        order: 110
    })

    app.sideMenuItems.addItem('settings', sideMenuItem(app))

    app.routes.addItem('currencyForm', {
        path: app.settingsGlobalTabs.getItem('currency').link,
        title: app.settingsGlobalTabs.getItem('currency').title,
        permission: app.settingsGlobalTabs.getItem('currency').permission,
        props: () => ({}),
        loader: () => import('@skunexus/global-currency'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsGlobalTabs.getAll())), { sub: true })(Component)
            )
        ),
    })
}