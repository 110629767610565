export default app => ({
    statuses: {
        id: 'Statuses',
        label: 'Statuses',
        title: 'Purchase Order Statuses',
        link: '/settings/purchase-orders/statuses',
        permission: 'purchase orders - statuses.index',
        order: 10
    },
    poNumber: {
        id: 'PO Number',
        label: 'PO Number',
        title: 'Purchase Order Number',
        link: '/settings/purchase-orders/po-number',
        permission: 'settings - general.save',
        order: 20
    }
})