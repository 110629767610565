import React from 'react';
import SkunexusPortal from './skunexusPortal';
import UserModel from './UserModel';
import VendorPortal from './vendorPortal';

export default () => {
  return UserModel.isVendor()
    ? <VendorPortal/>
    : <SkunexusPortal/>;
};
