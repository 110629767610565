import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'

import { Container, Button } from 'reactstrap';
import { Spinner } from "@skunexus/component-catalog";
import { getInvoiceData } from "../../api";

import InvoiceDetails from './InvoiceDetails'
import ItemsList from "./BarcodeList";
import TableContainer from "./TableContainer";

import styles from './styles.module.css';

export default () => {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getInvoiceData(setData, id);
  }, []);

  return (
    <Container
      className={styles.invoiceLayout}
    >
      {data ?
        <>
          <Button
            className={styles.printButton}
            onClick={() => window.print()}
            color="primary"
          >
            Print
          </Button>

          <InvoiceDetails
            label={data.label}
            barcodes={data.barcodes}
            billingAddress={data.billingAddress}
            shippingAddress={data.shippingAddress}
          />

          <hr/>

          <ItemsList
            barcodes={data.barcodes}
            containsPrescriptionGlasses={data.containsPrescriptionGlasses}
          />

          {data.containsLenses && <hr/>}

          <TableContainer
            isRender={data.containsLenses}
            prescription={data.lensesPrescription}
            title
          />
        </>
        :
        <div className={styles.spinnerWrapper}>
          <Spinner/>
        </div>
      }
    </Container>
  );
}
