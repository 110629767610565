import values from 'lodash/values';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';


export default app => ({
    inventorySettings: {
        path: app.settingsTabs.getItem('inventory').link,
        title: app.settingsTabs.getItem('inventory').title,
        permission: app.settingsTabs.getItem('inventory').permission,
        loader: () => import('@skunexus/inventory-settings'),
        render: Component => withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(Component),
    },
});