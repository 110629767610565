import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import { firstAllowedLink, combinePermissions } from '../../../utils';

import settingsTabs from './settingsTabs';
import routes from './routes';

export default app => {
    app.settingsWarehousesTabs = app.settingsWarehousesTabs || createConfigurableObject();
    app.settingsWarehousesTabs.addMany(settingsTabs(app));

    app.settingsTabs.addItem('warehouses', {
        label: 'Warehouses',
        link: () => firstAllowedLink(values(app.settingsWarehousesTabs.getAll())),
        permission: () => combinePermissions(values(app.settingsWarehousesTabs.getAll())),
        order: 3
    })

    app.routes.addMany(routes(app));
}