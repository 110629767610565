import get from 'lodash/get';
import keys from 'lodash/keys';

export default () => {
    const data = {};

    const addItem = (name, value) => {
        if(data[name]){
            throw new Error(`Config for ${name} that field already exist`);
        }

        data[name] = value;
    }

    const addMany = (object) => {
        keys(object).map(name => addItem(name, object[name]));
    }

    const deleteItem = name => {
        delete data[name]; 
    }

    const deleteMany = (array) => {
        array.map(name => deleteItem(name));
    }

    const changeItem = (name, value) => {
        if(!data[name]){
            throw new Error(`Config for ${name} field does not exist`);
        }

        data[name] = value;
    }

    const changeMany = (object) => {
        keys(object).map(name => changeItem(name, object[name]));
    }

    const getItem = name => get(data, name, null);

    const getAll = () => Object.assign({}, data);

    return {
        addItem,
        addMany,
        deleteItem,
        deleteMany,
        changeItem,
        changeMany,
        getItem,
        getAll
    }
}